<template>
    <v-container>
        <component v-for="(em, i) in enabledModules" :key="'component-' + i" :is="getModule(em)" :documentData="documentData" :error="error"></component>
    </v-container>
</template>

<script>
import BollettaInterattivaBody from './BollettaInterattivaBody.vue';
import BollettaInterattivaRiepilogoDoc from './BollettaInterattivaRiepilogoDoc.vue';
import BollettaInterattivaRiepilogoDoc2 from './BollettaInterattivaRiepilogoDoc2.vue';
import BollettaInterattivaChart from './BollettaInterattivaChart.vue';
import BollettaInterattivaChart2 from './BollettaInterattivaChart2.vue';
import BollettaInterattivaAvviso from './BollettaInterattivaAvviso.vue';
import BollettaInterattivaContatti from './BollettaInterattivaContatti.vue';
import BollettaInterattivaDownload from './BollettaInterattivaDownload.vue';
import BollettaInterattivaBanner from './BollettaInterattivaBanner.vue';
import BollettaInterattivaDatiPagamento from './BollettaInterattivaDatiPagamento.vue'
import ErrorPage from "../components/ErrorPage.vue";
import { services } from '../scripts/services/serviceBuilder'


export default ({
    components: {
        BollettaInterattivaBody,
        BollettaInterattivaRiepilogoDoc,
        BollettaInterattivaRiepilogoDoc2,
        BollettaInterattivaChart,
        BollettaInterattivaChart2,
        BollettaInterattivaAvviso,
        BollettaInterattivaContatti,
        BollettaInterattivaDownload,
        BollettaInterattivaBanner,
        BollettaInterattivaDatiPagamento,
        ErrorPage
    },
    props:{
      cryptoId: {
        type: String,
        default: null
      },
      documentId: {
        type: String,
        default: null
      },
      error: {
        type: Number,
        default: null
      }
    },
    data() {
        return {
            enabledModules: [],
            modules: {
                "riepilogo_doc": "BollettaInterattivaRiepilogoDoc",
                "riepilogo_doc2": "BollettaInterattivaRiepilogoDoc2",
                "sintesi_importi": "BollettaInterattivaChart",
                "sintesi_importi2": "BollettaInterattivaChart2",
                "fornitura": "BollettaInterattivaFornitura",
                "rate": "BollettaInterattivaBody",
                "info_e_contatti": "BollettaInterattivaContatti",
                "scarica_pdf": "BollettaInterattivaDownload",
                "banner_pubblicitario": "BollettaInterattivaBanner",
                "mancati_pagamenti": "BollettaInterattivaAvviso",
                "error_page": "ErrorPage",
                "dati_pagamento": "BollettaInterattivaDatiPagamento"
            },
            overlayVisible: true,
            documentData: null
        }
    },
    methods: {
        payBtnClicked: function(item) {
            console.log(item);
        },
        getModule(name){
            return this.modules[name];
        }
    },
    created: function () {
		var that = this;
        var requests = [];
        if(that.error == null && that.cryptoId != null) {
            requests.push(services.apiCallerBollettaInterattiva.modules());
            if(that.cryptoId != null)
                requests.push(services.apiCallerBollettaInterattiva.documentInfo(that.cryptoId));
            else if(that.documentId != null)
                requests.push(services.apiCallerBollettaInterattiva.documentInfoById(that.documentId));
            else {
                that.enabledModules = ['error_page'];
                return;
            }

            Promise.all(requests)
            .then(responses => {
                if(!!responses[0].data && !!responses[0].data['modules'] && Array.isArray(responses[0].data['modules'])){
                        that.enabledModules = responses[0].data['modules'];
                }

                var obj = responses[1].data;
                obj.crypto = that.cryptoId;
                that.documentData = obj;
            })
            .catch(error => {
                that.error = error.data;
                that.enabledModules = ['error_page'];
            });
        }
        else {
            that.enabledModules = ['error_page'];
        }
    }
})
</script>
